import { graphql } from "gatsby";
import * as React from "react";
import Layout from "~/components/Layout";
import { LayoutRenderer } from '~/components/layouts/LayoutRenderer';

import Seo from 'gatsby-plugin-wpgraphql-seo';

export default function fourOhFourPage({data: { wpPage, wp }}) {
  return (
    <Layout wp={wp}>
      {wpPage && <Seo post={wpPage} />}
      <LayoutRenderer
        prefix="Page_Posttypepage_Sections_Section_Components_"
        sections={wpPage?.postTypePage?.sections}
      />
    </Layout>
  );
}

export const pageQuery = graphql`
  query FourOhFour {
    wpPage(id: {eq: "cG9zdDoxMTk2"}) {
      ...SEO
      ...GeneratedWpPage
    }
    wp {
      ...GeneratedWp
    }
  }
`;
